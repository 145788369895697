import type { VCard } from '../server/src/vcard/vcard.entity.ts';

export const SHADOW_USER_CREDENTIALS = {
  phone: '+491000000000000',
  code: '9847',
};

export const DEFAULT_VCARD: Partial<VCard> = {
  style: {
    color: '#fff',
    'background-color': '#2F3150',
  },
  text1: '',
};

export const defaultLocale = process.env.DEFAULT_LANGUAGE as TranslatedLocales;

export enum TranslatedLocales {
  en = 'en',
  ar = 'ar',
  es = 'es',
  fr = 'fr',
  id = 'id',
  pt = 'pt',
  ru = 'ru',
  tr = 'tr',
}

// menu can use these languages (iso-639-1)
export enum MenuLocales {
  aa = 'aa',
  ab = 'ab',
  ae = 'ae',
  af = 'af',
  ak = 'ak',
  am = 'am',
  an = 'an',
  ar = 'ar',
  as = 'as',
  av = 'av',
  ay = 'ay',
  az = 'az',
  ba = 'ba',
  be = 'be',
  bg = 'bg',
  bi = 'bi',
  bm = 'bm',
  bn = 'bn',
  bo = 'bo',
  br = 'br',
  bs = 'bs',
  ca = 'ca',
  ce = 'ce',
  ch = 'ch',
  co = 'co',
  cr = 'cr',
  cs = 'cs',
  cu = 'cu',
  cv = 'cv',
  cy = 'cy',
  da = 'da',
  de = 'de',
  dv = 'dv',
  dz = 'dz',
  ee = 'ee',
  el = 'el',
  en = 'en',
  eo = 'eo',
  es = 'es',
  et = 'et',
  eu = 'eu',
  fa = 'fa',
  ff = 'ff',
  fi = 'fi',
  fj = 'fj',
  fo = 'fo',
  fr = 'fr',
  fy = 'fy',
  ga = 'ga',
  gd = 'gd',
  gl = 'gl',
  gn = 'gn',
  gu = 'gu',
  gv = 'gv',
  ha = 'ha',
  he = 'he',
  hi = 'hi',
  ho = 'ho',
  hr = 'hr',
  ht = 'ht',
  hu = 'hu',
  hy = 'hy',
  hz = 'hz',
  ia = 'ia',
  id = 'id',
  ie = 'ie',
  ig = 'ig',
  ii = 'ii',
  ik = 'ik',
  io = 'io',
  is = 'is',
  it = 'it',
  iu = 'iu',
  ja = 'ja',
  jv = 'jv',
  ka = 'ka',
  kg = 'kg',
  ki = 'ki',
  kj = 'kj',
  kk = 'kk',
  kl = 'kl',
  km = 'km',
  kn = 'kn',
  ko = 'ko',
  kr = 'kr',
  ks = 'ks',
  ku = 'ku',
  kv = 'kv',
  kw = 'kw',
  ky = 'ky',
  la = 'la',
  lb = 'lb',
  lg = 'lg',
  li = 'li',
  ln = 'ln',
  lo = 'lo',
  lt = 'lt',
  lu = 'lu',
  lv = 'lv',
  mg = 'mg',
  mh = 'mh',
  mi = 'mi',
  mk = 'mk',
  ml = 'ml',
  mn = 'mn',
  mr = 'mr',
  ms = 'ms',
  mt = 'mt',
  my = 'my',
  na = 'na',
  nb = 'nb',
  nd = 'nd',
  ne = 'ne',
  ng = 'ng',
  nl = 'nl',
  nn = 'nn',
  no = 'no',
  nr = 'nr',
  nv = 'nv',
  ny = 'ny',
  oc = 'oc',
  oj = 'oj',
  om = 'om',
  or = 'or',
  os = 'os',
  pa = 'pa',
  pi = 'pi',
  pl = 'pl',
  ps = 'ps',
  pt = 'pt',
  qu = 'qu',
  rm = 'rm',
  rn = 'rn',
  ro = 'ro',
  ru = 'ru',
  rw = 'rw',
  sa = 'sa',
  sc = 'sc',
  sd = 'sd',
  se = 'se',
  sg = 'sg',
  si = 'si',
  sk = 'sk',
  sl = 'sl',
  sm = 'sm',
  sn = 'sn',
  so = 'so',
  sq = 'sq',
  sr = 'sr',
  ss = 'ss',
  st = 'st',
  su = 'su',
  sv = 'sv',
  sw = 'sw',
  ta = 'ta',
  te = 'te',
  tg = 'tg',
  th = 'th',
  ti = 'ti',
  tk = 'tk',
  tl = 'tl',
  tn = 'tn',
  to = 'to',
  tr = 'tr',
  ts = 'ts',
  tt = 'tt',
  tw = 'tw',
  ty = 'ty',
  ug = 'ug',
  uk = 'uk',
  ur = 'ur',
  uz = 'uz',
  ve = 've',
  vi = 'vi',
  vo = 'vo',
  wa = 'wa',
  wo = 'wo',
  xh = 'xh',
  yi = 'yi',
  yo = 'yo',
  za = 'za',
  zh = 'zh',
  zu = 'zu',
}

export const LOCALE_NAMES: Record<string, string> = {
  aa: 'Afaraf',
  ab: 'аҧсуа бызшәа',
  ae: 'avesta',
  af: 'Afrikaans',
  ak: 'Akan',
  am: 'አማርኛ',
  an: 'aragonés',
  ar: 'اَلْعَرَبِيَّةُ',
  as: 'অসমীয়া',
  av: 'авар мацӀ',
  ay: 'aymar aru',
  az: 'azərbaycan dili',
  ba: 'башҡорт теле',
  be: 'беларуская мова',
  bg: 'български език',
  bi: 'Bislama',
  bm: 'bamanankan',
  bn: 'বাংলা',
  bo: 'བོད་ཡིག',
  br: 'brezhoneg',
  bs: 'bosanski jezik',
  ca: 'Català',
  ce: 'нохчийн мотт',
  ch: 'Chamoru',
  co: 'corsu',
  cr: 'ᓀᐦᐃᔭᐍᐏᐣ',
  cs: 'čeština',
  cu: 'ѩзыкъ словѣньскъ',
  cv: 'чӑваш чӗлхи',
  cy: 'Cymraeg',
  da: 'dansk',
  de: 'Deutsch',
  dv: 'ދިވެހި',
  dz: 'རྫོང་ཁ',
  ee: 'Eʋegbe',
  el: 'Ελληνικά',
  en: 'English',
  eo: 'Esperanto',
  es: 'Español',
  et: 'eesti',
  eu: 'euskara',
  fa: 'فارسی',
  ff: 'Fulfulde',
  fi: 'suomi',
  fj: 'vosa Vakaviti',
  fo: 'føroyskt',
  fr: 'Français',
  fy: 'Frysk',
  ga: 'Gaeilge',
  gd: 'Gàidhlig',
  gl: 'galego',
  gn: "Avañe'ẽ",
  gu: 'ગુજરાતી',
  gv: 'Gaelg',
  ha: 'هَوُسَ',
  he: 'עברית',
  hi: 'हिन्दी',
  ho: 'Hiri Motu',
  hr: 'Hrvatski',
  ht: 'Kreyòl ayisyen',
  hu: 'magyar',
  hy: 'Հայերեն',
  hz: 'Otjiherero',
  ia: 'Interlingua',
  id: 'Bahasa Indonesia',
  ie: 'Interlingue',
  ig: 'Asụsụ Igbo',
  ii: 'ꆈꌠ꒿ Nuosuhxop',
  ik: 'Iñupiaq',
  io: 'Ido',
  is: 'Íslenska',
  it: 'Italiano',
  iu: 'ᐃᓄᒃᑎᑐᑦ',
  ja: '日本語',
  jv: 'basa Jawa',
  ka: 'ქართული',
  kg: 'Kikongo',
  ki: 'Gĩkũyũ',
  kj: 'Kuanyama',
  kk: 'қазақ тілі',
  kl: 'kalaallisut',
  km: 'ខេមរភាសា',
  kn: 'ಕನ್ನಡ',
  ko: '한국어',
  kr: 'Kanuri',
  ks: 'कश्मीरी',
  ku: 'Kurdî',
  kv: 'коми кыв',
  kw: 'Kernewek',
  ky: 'Кыргызча',
  la: 'latine',
  lb: 'Lëtzebuergesch',
  lg: 'Luganda',
  li: 'Limburgs',
  ln: 'Lingála',
  lo: 'ພາສາລາວ',
  lt: 'lietuvių kalba',
  lu: 'Kiluba',
  lv: 'latviešu valoda',
  mg: 'fiteny malagasy',
  mh: 'Kajin M̧ajeļ',
  mi: 'te reo Māori',
  mk: 'македонски јазик',
  ml: 'മലയാളം',
  mn: 'Монгол хэл',
  mr: 'मराठी',
  ms: 'Bahasa Melayu',
  mt: 'Malti',
  my: 'ဗမာစာ',
  na: 'Dorerin Naoero',
  nb: 'Norsk bokmål',
  nd: 'isiNdebele',
  ne: 'नेपाली',
  ng: 'Owambo',
  nl: 'Nederlands',
  nn: 'Norsk nynorsk',
  no: 'Norsk',
  nr: 'isiNdebele',
  nv: 'Diné bizaad',
  ny: 'chiCheŵa',
  oc: 'occitan',
  oj: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  om: 'Afaan Oromoo',
  or: 'ଓଡ଼ିଆ',
  os: 'ирон æвзаг',
  pa: 'ਪੰਜਾਬੀ',
  pi: 'पाऴि',
  pl: 'Polski',
  ps: 'پښتو',
  pt: 'Português',
  qu: 'Runa Simi',
  rm: 'rumantsch grischun',
  rn: 'Ikirundi',
  ro: 'Română',
  ru: 'Русский',
  rw: 'Ikinyarwanda',
  sa: 'संस्कृतम्',
  sc: 'sardu',
  sd: 'सिन्धी',
  se: 'Davvisámegiella',
  sg: 'yângâ tî sängö',
  si: 'සිංහල',
  sk: 'slovenčina',
  sl: 'slovenščina',
  sm: "gagana fa'a Samoa",
  sn: 'chiShona',
  so: 'Soomaaliga',
  sq: 'Shqip',
  sr: 'српски језик',
  ss: 'SiSwati',
  st: 'Sesotho',
  su: 'Basa Sunda',
  sv: 'Svenska',
  sw: 'Kiswahili',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  tg: 'тоҷикӣ',
  th: 'ไทย',
  ti: 'ትግርኛ',
  tk: 'Türkmençe',
  tl: 'Wikang Tagalog',
  tn: 'Setswana',
  to: 'faka Tonga',
  tr: 'Türkçe',
  ts: 'Xitsonga',
  tt: 'татар теле',
  tw: 'Twi',
  ty: 'Reo Tahiti',
  ug: 'ئۇيغۇرچە‎',
  uk: 'Українська',
  ur: 'اردو',
  uz: 'Ўзбек',
  ve: 'Tshivenḓa',
  vi: 'Tiếng Việt',
  vo: 'Volapük',
  wa: 'walon',
  wo: 'Wollof',
  xh: 'isiXhosa',
  yi: 'ייִדיש',
  yo: 'Yorùbá',
  za: 'Saɯ cueŋƅ',
  zh: '中文',
  zu: 'isiZulu',
};

export const CUSTOMERS_PER_PAGE = 15;
export const ORDERS_PER_PAGE = 20;
export const REVIEWS_PER_PAGE = 10;
export const TABLES_PER_PAGE = 20;
export const MAILING_PER_PAGE = 20;
export const LOYALTY_HISTORY_PER_PAGE = 20;
export const PROMO_CODES_PER_PAGE = 20;

export const ANNUAL_PLAN_DISCOUNT = 30;

export enum MailingRecipient {
  ALL = 'ALL',
  LOYALTY_PROGRAMS = 'LOYALTY_PROGRAMS',
  CUSTOMERS = 'CUSTOMERS',
}

export enum MailingGateway {
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
}

export const MAX_LOYALTY_REDEEM = 0.2; // 20%;

export enum ALLERGEN {
  GLUTEN = 'GLUTEN',
  TREE_NUTS = 'TREE_NUTS',
  MILK = 'MILK',
  EGGS = 'EGGS',
  FISH = 'FISH',
  SHELLFISH = 'SHELLFISH',
  PEANUTS = 'PEANUTS',
  SOYBEANS = 'SOYBEANS',
  WHEAT = 'WHEAT',
  SESAME = 'SESAME',
  SEAFOOD = 'SEAFOOD',
  LACTOSE = 'LACTOSE',
  NUTS = 'NUTS',
  ALCOHOL = 'ALCOHOL',
}

export enum PANEL_FEATURES {
  'MENU' = 'MENU',
  'POS' = 'POS',
  'ORDERS' = 'ORDERS',
  'ORDER_AT_TABLE' = 'ORDER_AT_TABLE',
  'FEEDBACK' = 'FEEDBACK',
  'LOYALTY' = 'LOYALTY',
  'MAILINGS' = 'MAILINGS',
  'MULTILINK' = 'MULTILINK',
  'GOOGLE_REVIEWS' = 'GOOGLE_REVIEWS',
  'BOOKING' = 'BOOKING',

  'NEW_UNIT' = 'NEW_UNIT',
}

export const ORGANIZATION_IDS = {
  FRANK: 'fc4d421d-f61e-4d97-b159-2f5d55845f7f',
  LEO_LOONA: '9e51f754-0f71-4104-aeac-5f5e3867c5f0',
  BAZA: '5413bc1e-0cbb-4785-ba66-3b30b03f6f79',
  ABC: '7cd54351-c12f-4177-9d60-d3ac002ba120',
  HOOKAH_PLACE_RU: 'b64caab6-6b9a-4d60-b6c7-20d7d5a5be0b',
  SANGRE_FRESCA_RU: 'f80d787b-3394-45f7-b3f5-e576e88cb3f5',
  BAQALA: '303dee84-d273-443d-9fba-861a7d4669cc',
};

export const UNIT_IDS = {
  FRANK_BLUE_WATERS: '177b74e5-4368-4649-8d90-e93bb7f75a8e',
  HOOKAH_FOREVER_RU: '2f2f82cf-9964-4e34-9c98-61f86c8fe0ff',
  SANGRE_FRESCA_RU: '37dc374c-9815-4979-a4ad-0279cb5edd2e',
  COSY_CUISINE: 'e63d9b9c-0c2a-4067-9443-dd19bc609f52',
};

export enum CustomerExtraType {
  'CHILD' = 'CHILD',
  'CAR' = 'CAR',
}

export const CUSTOMER_EXTRA_FOR_ORGANIZATION = {
  [ORGANIZATION_IDS.BAZA]: CustomerExtraType.CAR,
  [ORGANIZATION_IDS.LEO_LOONA]: CustomerExtraType.CHILD,
};
