import { defaultLocale } from 'shared/constants';

export const unitLocationMap = {
  '011a86aa-3ae4-4d48-b628-6f1c73a57f36': ['Business Bay'],
  '3e6649b9-8c81-4799-9a05-707866b26572': [
    'Marina ',
    'Tecom',
    'Greens',
    'JLT',
    'Palm',
    'JBR',
    'Internet City ',
    'Media City ',
    'JVC',
  ],
  '9ed445b8-bffa-43fe-b02a-0cdb75ae1a08': ['Healthcare city'],
  '3d40fe8a-364b-4a32-be05-5365ee6d2dc3': ['JBR', 'Marina', 'JLT'],
  '82c85f84-ec36-448e-92b2-f59b511ea779': [
    'Jabel ali frezone South & North',
    'Tecnopark',
    'Jabel ali industrial area 1&2',
    'Al furjan',
    'Dip 1 & 2',
    'Dubai parks & resorts',
    'Dubai world central',
    'Dubai south',
    'Ibn battuta',
    'Jabel ali downtown',
  ],
  '66da2d6b-7f88-4d4b-af58-1a11bf089093': ['Karama'],
  '36047e42-1e3a-4b5d-9e63-2e7daaf13dc9': ['Bur Dubai'],
  '339006ab-767e-499d-bafe-43a900b9522f': ['Al Barsha 1', 'Tecom', 'Dubai Marina', 'JVC'],
};

export const fallbackLocale = defaultLocale;
