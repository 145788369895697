import { init, getLocaleFromNavigator, locale, register, addMessages } from 'svelte-i18n';
import { fallbackLocale } from '../constants';
import { TranslatedLocales } from 'shared/constants';

function handleMissingMessage({
  locale,
  id,
  defaultValue,
}: {
  locale: string;
  id: string;
  defaultValue: string | undefined;
}) {
  console.error('i18n missing key', locale, id, defaultValue);
}

for (const translatedLocale in TranslatedLocales) {
  register(translatedLocale, () => import(`./locales/${translatedLocale}.json`));
}

void init({
  fallbackLocale,
  initialLocale: getInitialLocale(),
  handleMissingMessage,
});

// locale.subscribe((lng) => {
//   if (lng) {
//     localStorage.setItem('svelte-i18n-locale', lng);
//   }
// });

function getInitialLocale(): string {
  try {
    const userLanguage = window.navigator.language.split('-')[0].toLowerCase();
    if (Object.keys(TranslatedLocales).includes(userLanguage)) {
      return userLanguage as TranslatedLocales;
    }
  } catch (e) {
    console.error(e);
  }

  return fallbackLocale;
}
